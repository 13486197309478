import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Personvern" />
    <div className="longtext">
      <h1>Personvern</h1>
      <p className="secondary">Sist endret 30. januar 2022</p>

      <h2>Oppsummert</h2>
      <ul>
        <li>
          Dataene dine er trygt lagret hos vår skyleverandør og deles ikke med
          noen andre uten at du selv velger det
        </li>
        <li>
          Du kan få relevante, hjelpsomme eposter fra oss rundt bruk av appen
        </li>
      </ul>

      <h2>For deg som vil ha detaljene</h2>

      <p>
        Mente er en tjeneste som hjelper deg med kontinuerlig forbedring gjennom
        smarte lister, oppgaver og refleksjon.
      </p>

      <p>
        Denne personvernerklæringen gir deg informasjon om hvordan Mente AS
        (heretter “Mente”, “vi”, “oss”) behandler personopplysninger om deg
        (“du”).
      </p>

      <h2>Hvilken type person&shy;opplysninger vi behandler, og hvorfor</h2>
      <p>
        Personopplysninger er opplysninger og vurderinger som kan knyttes til
        deg som enkeltperson.
      </p>

      <p>De personopplysningene vi behandler om deg som bruker, er:</p>
      <ul>
        <li>e-postadressen og navnet ditt</li>
        <li>innhold du selv legger inn i appen</li>
        <li>informasjon om hvilket abonnementet du har</li>
      </ul>

      <p>
        Formålet med vår behandling av dine personopplysninger er at vi skal
        kunne identifisere deg som bruker, levere tjenesten som avtalt (for
        eksempel lagre og synkronisere innhold på tvers av enheter du bruker),
        kommunisere med deg, og forbedre tjenesten.
      </p>

      <p>Mente behandler personopplysninger om deg:</p>
      <ul>
        <li>når de er nødvendige for å tilby tjenestene våre</li>
        <li>for å overholde juridiske forpliktelser </li>
        <li>når vi har berettigede interesser for behandlingen</li>
      </ul>

      <h2>Hvem deler vi person&shy;opplysningene med</h2>
      <p>
        Utgangspunktet er at Mente ikke deler personopplysninger med noen. Vi
        deler bare personopplysningene dine hvis det er helt nødvendig, og i så
        fall så lite som mulig.
      </p>

      <p>
        Vi deler personopplysninger med politi, domstoler eller andre offentlige
        myndigheter som har hjemmel til å anmode om utlevering.
      </p>

      <p>
        I tillegg deler vi personopplysninger med følgende leverandører for å
        kunne levere tjenesten:
      </p>

      <h3>Google Cloud Firestore</h3>
      <dl>
        <dt>Opplysninger</dt>
        <dd>
          Alt innhold du selv legger inn i appen (eks. lister, oppgaver og
          refleksjoner)
        </dd>

        <dt>Hvorfor brukes den?</dt>
        <dd>For backup og synkronisering av data på tvers av enheter</dd>

        <dt>Hvor er opplysningene lagret?</dt>
        <dd>i Europa</dd>
      </dl>

      <h3>Google Firebase Authentication</h3>
      <dl>
        <dt>Opplysninger</dt>
        <dd>E-postadresse og navn</dd>

        <dt>Hvorfor brukes den?</dt>
        <dd>For å identifisere deg som bruker</dd>

        <dt>Hvor er opplysningene lagret?</dt>
        <dd>i USA</dd>
      </dl>

      <h3>Mixpanel</h3>
      <dl>
        <dt>Opplysninger</dt>
        <dd>
          Når og hvordan du bruker ulike funksjoner i Mente. For eksempel når du
          oppretter en ny liste. Personlig innhold sendes aldri med. IP-adresse
          blir sendt med for å utlede hvilket land og by du er i, men lagres
          ikke.
        </dd>

        <dt>Hvorfor brukes den?</dt>
        <dd>
          For å se hvordan appen brukes, slik at vi igjen får innsikt til videre
          produktutvikling
        </dd>

        <dt>Hvor er opplysningene lagret?</dt>
        <dd>i USA</dd>
      </dl>

      <p>
        Vi inngår alltid databehandleravtaler med leverandører for å sikre at vi
        overholder våre forpliktelser etter personvernforordningen (GDPR), og at
        leverandørene ikke bruker dataene til andre formål. Hvis
        personopplysninger lagres utenfor EU/EØS, sørger vi for at
        leverandøren/landet sikrer et tilstrekkelig beskyttelsesnivå, for
        eksempel EU Model Contract Clauses.
      </p>

      <h2>Hvilke rettigheter du har</h2>
      <p>Siden vi behandler personopplysninger om deg, har du rett til å:</p>
      <ul>
        <li>å vite hva slags opplysninger vi har samlet om deg.</li>
        <li>
          at vi retter og oppdaterer personopplysningene dine hvis de ikke er
          riktige.
        </li>
        <li>å bli glemt og at vi sletter opplysningene dine.</li>
        <li>
          at vi utleverer dine egne opplysninger til deg (dataportabilitet).
        </li>
        <li>å protestere mot behandlingen.</li>
        <li>
          å klage på vår behandling av personopplysninger eller sende en klage
          til{" "}
          <a href="https://www.datatilsynet.no/en/about-us/contact-us/">
            Datatilsynet
          </a>
          .
        </li>
      </ul>

      <p>
        Send en e-post til <a href="mailto:hello@mente.app">hello@mente.app</a>{" "}
        hvis du har spørsmål eller ønsker å utøve dine rettigheter.
      </p>

      <h2>Oppbevaring av personopplysninger</h2>
      <h3>Varighet</h3>
      <p>
        Vi lagrer personopplysninger så lenge du har en brukerkonto hos oss.
        Dersom du ber om sletting av brukerkontoen din vil du miste tilgangen
        til tjenesten og dataene dine vil bli slettet i løpet av 3 måneder.
      </p>

      <h3>Datasikkerhet</h3>
      <ul>
        <li>Trafikken mellom app og datasentre er kryptert.</li>
        <li>
          Ditt eget innhold i appen (lister, oppgaver osv.) blir lagret kryptert
          i datasentre hos Google (“encrypted at rest”).
        </li>
        <li>
          Sjekklister og rutiner brukes for å jevnlig gjennomgå sikkerheten i
          app, infrastruktur og hos ansatte.
        </li>
      </ul>

      <h2>Endringer</h2>
      <p>
        Vi vil fra tid til annen gjøre endringer i denne personvernerklæringen.
        Oppdatert versjon finner du til enhver tid i appen og på våre nettsider.
        Dersom det gjøres endringer i behandlingen av personopplysninger, vil
        erklæringen oppdateres. Større endringer varsles i appen og/eller på
        epost.
      </p>

      <p>**</p>

      <p>
        Spørsmål og henvendelser om personvern kan sendes til{" "}
        <a href="mailto:hello@mente.app">hello@mente.app</a>.
      </p>
    </div>
  </Layout>
)

export default Page
